const featuredProducts3 = [
    {
        _id: "6651a17ec11b7b3f8b5d0756",
        productName: "FLOOR SPRING DOOR",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 3200,
        discountPrice: 3000,
        image: "https://i.ibb.co/98vnB1j/Floor-spring-door.png",
        // brandName: "GDC",
    },
    {
        _id: "6651a17ec11b7b3f8b5d0756",
        productName: "GLASS HANDLE",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 1500,
        discountPrice: 1200,
        image: "https://i.ibb.co/F5brgyP/Glass-handle.png",
        // brandName: "GDC",
    },
    {
        _id: "6651a219c11b7b3f8b5d0767",
        productName: "GLASS PIVOT",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 200,
        discountPrice: 100,
        image: "https://i.ibb.co/t4k5yQB/glass-pivot.png",
        brandName: "GDC"
    },

    {
        _id: "6651a219c11b7b3f8b5d0767",
        productName: "GLASS TO GLASS LOCK",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 1350,
        discountPrice: 1200,
        image: "https://i.ibb.co/S7Gq7Lb/Glass-to-glass-lock.png",
        brandName: "GDC"
    },
    {
        _id: "6651a219c11b7b3f8b5d0767",
        productName: "PROFILE CONNECTOR",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 100,
        discountPrice: 80,
        image: "https://i.ibb.co/PmZpdYX/profile-connector.png",
        brandName: "GDC"
    },
    {
        _id: "6651a219c11b7b3f8b5d0767",
        productName: "L PATCH FITTING",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 1350,
        discountPrice: 1200,
        image: "https://i.ibb.co/CQCDDdL/L-patch-fitting.png",
        brandName: "GDC"
    },
    {
        _id: "6651a219c11b7b3f8b5d0767",
        productName: "TOP BOTTOM PATCH FITTING",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 800,
        discountPrice: 700,
        image: "https://i.ibb.co/8DyG6yB/Top-bottom-patch-fitting.png",
        brandName: "GDC"
    },
    {
        _id: "6651a219c11b7b3f8b5d0767",
        productName: "Glass To Wall Lock",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 1200,
        discountPrice: 1100,
        image: "https://i.ibb.co/9Tyvdfq/glass-to-wall.png",
        brandName: "HPZ"
    },
]

export default featuredProducts3;