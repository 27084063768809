import { NavLink } from "react-router-dom";
import { useEffect } from "react";

import Logo from "../images/logo.jpg";
import Cart from "./Cart";
import Footer from "./Footer";
import "../../style/About.css";
import SearchBox from "./Searchbox";
import NavBar from "./Navbar";

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    return (
        <>
            <div className="aboutMain" style={{minHeight:"180px" , paddingBottom:"30px"}}>
                {/* top line */}
                <div className="topBarMain">

                    <div className="topBar">
                        <div className="topBarOne">
                            <p style={{ fontFamily: "'Jost', sans-serif" }} >FREE SHIPPING FOR ALL ORDERS ABOVE ₹ 1,999</p>
                        </div>
                        <div className="topBarTwo">
                            <i className="fa-brands fa-facebook-f"></i>
                            <a style={{ textDecoration: "none", color: "black" }} href="/">
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                            <a style={{ textDecoration: "none", color: "black" }} href="https://www.google.com/maps/place/HPZ+WORLDWIDE+PRIVATE+LIMITED/@17.3845779,78.453285,17z/data=!3m1!4b1!4m6!3m5!1s0x3bcb9972611be379:0x58f98e4ab2925934!8m2!3d17.3845779!4d78.4558599!16s%2Fg%2F11s8y3t5qf?entry=tts&g_ep=EgoyMDI0MTAyMy4wIPu8ASoASAFQAw%3D%3D">
                                <i className="fa-brands fa-google"></i>
                            </a>
                            <a style={{ textDecoration: "none", color: "black" }} href="https://wa.me/919440295353">
                                <i className="fa-brands fa-whatsapp"></i>
                            </a>
                            {/* <i className="fa-brands fa-telegram"></i> */}
                        </div>
                    </div>

                </div>

                {/* Navbar */}
                <NavBar />

            </div>

            {/* About content */}

            <div className="aboutCntxtMain">
                <div className="aboutCntxt">
                    <div className="abtImgDiv">
                        <img src={Logo} alt="" />
                    </div>
                    <div className="abtContentDiv">
                        <h2 style={{ borderLeft: "7px solid hsla(55, 90%, 67%, 1)", paddingLeft: "20px", position: "relative", left: "10px", margin: "20px auto" }} >ABOUT US</h2>
                        <p>Welcome to HPZ Hardware, a pioneering enterprise established in 1995 in Hyderabad by a team of experienced hardware professionals. Our journey began with a straightforward yet profound mission: to provide high-quality hardware products that seamlessly integrate with both the art and science of construction and maintenance, ensuring lasting solutions for professionals and DIY enthusiasts alike.</p>
                        <p>At HPZ Hardware, we combine innovation with expertise, striving to offer a comprehensive selection of state-of-the-art products that empower craftsmen and builders to elevate their work standards. With an unwavering commitment to excellence, we constantly push the boundaries of hardware technology to equip our customers with the tools they need to stay ahead in their projects.</p>
                    </div>
                </div>
                <div className="abtContentDiv" style={{ width: "100%", maxWidth: "1222px" }}>
                    <p style={{ padding: "2px" }}>Our ethos centers on the belief that every hardware solution should not only meet but surpass expectations. Therefore, we meticulously design and develop our products with a focus on long-term compatibility, durability, and performance, ensuring optimal results and satisfaction for our customers.</p>
                    <p style={{ padding: "2px" }}>Driven by a passion for innovation and a dedication to quality, we are committed to serving the hardware community both nationally and internationally. Our goal is not just to meet industry standards but to redefine them, setting new benchmarks in hardware solutions and advancing the field of construction and maintenance.</p>
                    <p style={{ padding: "2px" }}> As we continue our journey towards excellence, we invite you to explore our range of technologically advanced products and experience the HPZ Hardware difference. Together, let’s build a future where hardware excellence knows no limits.</p>
                </div>
            </div>

            {/* footer */}
            <Footer />

        </>
    )
}

export default About;