import PremiumBall from "../images/category images/Kitchen fittings/Ball bearing.png"

const featuredProducts = [
    {
        _id: "6650fc3b95bc3138c048fc07",
        productName: "Premium 45kg Ball Bearing Runner ",
        productCat: "Kitchen Fittings",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 400,
        discountPrice: 340,
        discountPercentage: 20,
        image: "https://i.ibb.co/QMshxts/Ball-bearing.png",
        // brandName: "Prevest DenPro"
    },
    {

        _id: "6650fd1b6f483e3b73d726c4",
        productName: "Gas Pump",
        productCat: "Kitchen Fittings",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 250,
        discountPrice: 230,
        image: "https://i.ibb.co/Ksqczs4/Gas-pump.png",
        // brandName: "Prevest DenPro"

    },
    {

        _id: "6650fd796f483e3b73d726ca",
        productName: "Slide On Hinge 2 Hole Mounting Plate",
        productCat: "Kitchen Fittings",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 200,
        discountPrice: 180,
        image: "https://i.ibb.co/SsCf0vy/Hole-Mounting.png",
        // brandName: "Prevest DenPro"

    },
    {
        _id: "6650fd4e6f483e3b73d726c7",
        productName: "SS Welded Hinges",
        productCat: "Kitchen Fittings",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 80,
        discountPrice: 60,
        image: "https://i.ibb.co/rdjRThx/SS-welded.png",
        // brandName: "Prevest DenPro"

    },
    {
        _id: "665124c109c864e8a41bfa06",
        productName: "Normal Hinge",
        productCat: "Kitchen Fittings",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 100,
        discountPrice: 80,
        image: "https://i.ibb.co/KW3Scm0/normal-hinge.png",
        // brandName: "GDC"
    },
    {
        _id: "6651a17ec11b7b3f8b5d0756",
        productName: "45kgs Softt Close Ball Bearing Runners",
        productCat: "Kitchen Fittings",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 1500,
        discountPrice: 1100,
        image: "https://i.ibb.co/WKWhr3v/Soft-close-ball-bearing.png",
        // brandName: "GDC",
    },
    {
        _id: "6651a219c11b7b3f8b5d0767",
        productName: "Profile Hinges",
        productCat: "Kitchen Fittings",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 100,
        discountPrice: 80,
        image: "https://i.ibb.co/GnfHHpy/Profile-Hinges.png",
        // brandName: "GDC"
    },
    {
        _id: "665435936172594239a6f69b",
        productName: "Softt Close Hinges 165",
        productCat: "Kitchen Fittings",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 700,
        discountPrice: 600,
        image: "https://i.ibb.co/DzgRYZr/Soft-close-hinges-165.png",
        // brandName: "GDC",
    }
];


export default featuredProducts;