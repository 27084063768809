import { useNavigate, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import "./style/Main.css";
import "./style/Common.css";
import "./style/Products.css";
import Logo from "../components/images/logo.jpg";

const NewProduct = () => {

    const navigate = useNavigate();

    const [productData, setProductData] = useState({
        productName: "", actualPrice: "", discountPrice: "", image: "", brandName: "", mpkg: ""
    });

    const tokenn = Cookies.get("HPZ Hardware Worldwide");
    const [error, setError] = useState("");


    const [showProfiles, setShowProfiles] = useState(false);
    const [expandCat, setexpandCats] = useState("hidden");
    const settingCatExpand = () => {
        if (expandCat === "hidden") {
            setexpandCats("visible")
        } else {
            setexpandCats("hidden")
        }
    }

    const setLoginVal = (e) => {
        const { name, value } = e.target;
        setProductData({ ...productData, [name]: value });
    }

    const [stockAvail, setSTockAvail] = useState(true)
    const [visibility, setvisibility] = useState(true)
    const [productCat, setCat] = useState("Category");

    const [colorInput, setColorInput] = useState("")
    const [colorArr, setcolorArr] = useState([])

    const removeClr = (value) => {
        let removedClrArr = colorArr.filter((val) => {
            return val !== value
        })

        setcolorArr(removedClrArr)
    }

    const [sizesInput, setSizesInput] = useState({
        size: "", price: ""
    })
    const [sizesArr, setsizesArr] = useState([])

    const setSizenPrice = (e) => {
        const { name, value } = e.target;
        setSizesInput({ ...sizesInput, [name]: value });
    }

    const removeSize = (value) => {
        let removedClrArr = sizesArr.filter((val) => {
            return val.size !== value
        })

        setsizesArr(removedClrArr)
    }

    const addProduct = async (e) => {

        e.preventDefault();

        const { productName, actualPrice, discountPrice, image, brandName, mpkg } = productData;
        console.log(productName, productCat, visibility, actualPrice, discountPrice, image, brandName, mpkg, sizesArr, colorArr, tokenn)

        if (productCat === "Category") {
            setError("Please Select a category")
        } else {
            try {
                const res = await fetch(`https://hpzserver.vercel.app/usersasqwzxerdfcv/post/product`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        productName, productCat, visibility, inStock: stockAvail, actualPrice, discountPrice, image, brandName, mpkg, sizes:sizesArr, color:colorArr, tokenn
                    })
                });

                const data = await res.json();

                if (res.status === 200) {
                    alert("Product Added Success!")
                    setError("")
                } else {
                    if (data.error === "User not found") {
                        setError("Authentication Failed... Kindly login again")
                    } else if (data.error === "product already registered") {
                        setError("Product already added")
                    } else if (data.error === "Enter all the details") {
                        setError("Please fill all the details!")
                    }
                }

            } catch (error) {
                console.log(error);
            }
        }


    }

    // useEffect(() => {
    //     gettingDataHome();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])



    return (<>
        <div className="dashMain">

            {/* slide */}
            <div className="dashMainSlide">

                {/* img */}
                <NavLink to="/dashboard/home" style={{ color: "white", textDecoration: "none" }} className="mainImg">
                    <img src={Logo} alt="logo" />
                </NavLink>

                <div className="mainServices">
                    <NavLink className="mainServicesspan" to="/dashboard/profile" ><i className="fa-solid fa-user"></i><span>Profile</span></NavLink>
                    <div style={{ display: "flex", flexDirection: "column" }} onMouseEnter={() => setShowProfiles(true)} onMouseLeave={() => setShowProfiles(false)}>
                        <span className="mainServicesspan selectedService"  ><i className="fa-solid fa-box-archive"></i><span>Products</span></span>
                        {
                            showProfiles ? <div style={{ display: "flex", flexDirection: "column" }}  >
                                <NavLink className="mainServicesspan" to="/dashboard/allproducts">All Products</NavLink>
                                <NavLink className="mainServicesspan" to="/dashboard/newproducts">New Products</NavLink>
                                <NavLink className="mainServicesspan" to="/dashboard/productscat">Categories</NavLink>
                            </div> : ""
                        }
                    </div>
                    <NavLink className="mainServicesspan" to="/dashboard/orders"><i className="fa-solid fa-cart-shopping"></i><span>Orders</span></NavLink>
                    <NavLink className="mainServicesspan" to="/dashboard/customers"><i className="fa-solid fa-people-group"></i><span>Customers</span></NavLink>
                    <NavLink style={{ marginLeft: "3px" }} className="mainServicesspan" to="/dashboard/forms"><i className="fa-solid fa-book"></i><span style={{ marginLeft: "3px" }}  >Forms</span></NavLink>
                </div>

            </div>

            {/* Part next to start */}

            <div className="dashMainCntxt" style={{ background: "#ececec" }}>

                {/* // productName, productCat, visibility, taxStatus, taxClass, inStock, actualPrice, discountPrice, image, brandName, tokenn */}
                <h2 style={{ borderLeft: "7px solid rgb(243, 163, 50)", paddingLeft: "20px", position: "relative", left: "35px" }} >ADD NEW PRODUCT</h2>

                <div className="dashProductsMain" style={{ alignItems: "flex-start", margin: "70px auto" }}>
                    <div className="dashProducts">
                        <div className="dashProductsInput">

                            <div className="dashInput1">
                                <input onChange={setLoginVal} type="text" placeHolder="Product Name" name="productName" value={productData.productName} />

                                <div onClick={settingCatExpand} className="productCatDiv" placeHolder="Product Category" >
                                    <span className="catBtn" >{productCat} <i className="fa-solid fa-chevron-down"></i></span>
                                    <div style={{ visibility: expandCat }} >
                                        <span onClick={() => setCat("Kitchen Fittings")} >Kitchen Fittings</span>
                                        <span onClick={() => setCat("Wardrobe Fittings")} >Wardrobe Fittings</span>
                                        <span onClick={() => setCat("Glass Fittings")} >Glass Fittings</span>
                                        <span onClick={() => setCat("Aluminium Profile")} >Aluminium Profile</span>
                                    </div>
                                </div>

                            </div>

                            <div className="dashInput1">
                                <input onChange={setLoginVal} type="text" placeHolder="Brand Name" name="brandName" value={productData.brandName} />
                                <input onChange={setLoginVal} type="text" placeHolder="Image URL" name="image" value={productData.image} />
                            </div>

                            <div className="dashInput1">
                                <input onChange={setLoginVal} type="number" placeHolder="Actual Price" name="actualPrice" value={productData.actualPrice} />
                                <input onChange={setLoginVal} type="number" placeHolder="Discount Price" name="discountPrice" value={productData.discountPrice} />
                            </div>

                            <div className="dashInput1">
                                <input onChange={setLoginVal} type="number" placeHolder="M/PKG" name="mpkg" value={productData.mpkg} />
                                <div className="stockDiv" placeHolder="Product Category" name="productCat" value={""} >
                                    <span className={visibility ? "stockDivSelected" : ""} onClick={() => setvisibility(true)} >Visible</span>
                                    <span className={visibility ? "" : "stockDivSelected"} onClick={() => setvisibility(false)} >Hide</span>
                                </div>

                                <div className="stockDiv" style={{ position: "relative", left: "-5px" }}>
                                    <span className={stockAvail ? "stockDivSelected" : ""} onClick={() => setSTockAvail(true)} >In Stock</span><span className={stockAvail ? "" : "stockDivSelected"} onClick={() => setSTockAvail(false)} >Out of Stock</span>
                                </div>
                            </div>

                            <div className="dashInput1">

                                <div className="productColor">
                                    <div className="productColorSub">
                                        <input type="text" className="colorInput" name="colorInput" value={colorInput} onChange={(e) => setColorInput(e.target.value)} placeHolder="Add colours" style={{ width: "433px" }} /><button onClick={() => setcolorArr([...colorArr, colorInput])}>+</button>
                                    </div>
                                    <div className="colorOptions">
                                        {colorArr.map((value) => {
                                            return (
                                                <div className="addedInputs">
                                                    <p>{value}</p>
                                                    <i className="fa fa-trash" onClick={() => removeClr(value)}></i>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div className="productColor">
                                    <div className="productColorSub">
                                        <input type="text" className="colorInput" name="size" value={sizesInput.size} onChange={setSizenPrice} placeHolder="Add sizes" style={{ width: "220px", borderRadius: "10px 0px 0px 10px" }} />
                                        <input type="number" className="colorInput" name="price" value={sizesInput.price} onChange={setSizenPrice} placeHolder="Prices for size" style={{ width: "220px", position: "relative", left: "-46px", borderRadius: "0px 10px 10px 0px" }} />
                                        <button style={{ position: "relative", left: "-70px" }} onClick={() => setsizesArr([...sizesArr, sizesInput])}>+</button>
                                    </div>
                                    <div className="colorOptions">
                                        {sizesArr.map((value) => {
                                            return (
                                                <div className="addedInputs">
                                                    <p>{value.size}</p>
                                                    <p>{value.price}</p>
                                                    <i className="fa fa-trash" onClick={() => removeSize(value.size)}></i>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="dashProductsSubmit">
                            <p style={{ color: "red", fontSize: "18px", padding: "5px", fontFamily: "'Montserrat', sans-serif" }} >{error}</p>
                            <button onClick={addProduct} >PUBLISH</button>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </>)
}

export default NewProduct;