import { useNavigate, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import "./style/Main.css";
import "./style/Common.css";
import "./style/Products.css";
import Logo from "../components/images/logo.jpg";

const ProductsCat = () => {

    const navigate = useNavigate();
    const tokenn = Cookies.get("HPZ Hardware Worldwide");
    const [showProfiles, setShowProfiles] = useState(false);

    const [catData, setCatData] = useState([])

    const gettingDataHome = async (e) => {

        try {
            const res = await fetch(`https://hpzserver.vercel.app/usersasqwzxerdfcv/allCategories`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });

            const data = await res.json();

            if (res.status === 200) {
                setCatData(data)
                console.log(catData)
            } else {
                // navigate("/usernotfound");
            }

        } catch (error) {
            console.log(error);
            // navigate("/usernotfound");
        }

    }

    const [catName, setcatName] = useState("");
    const [error, setError] = useState("");

    const postCategory = async (e) => {

        e.preventDefault();

        try {
            const res = await fetch("https://hpzserver.vercel.app/usersasqwzxerdfcv/post/category", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    catName, tokenn
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                alert("Category Added")
                gettingDataHome();
            } else {
                if (data.error === "Passwords doesnt match") {
                    setError("Passwords doesn't match")
                } else if (data.error === "Already exists") {
                    setError("Category already exists")
                } else if (data.error === "Enter all the details") {
                    setError("Please enter the category name")
                }
            }

        } catch (error) {
            console.log(error)
        }

    }

    const deleteCategory = async (e, catName) => {

        e.preventDefault();

        try {
            const res = await fetch("https://hpzserver.vercel.app/usersasqwzxerdfcv/deleteCat", {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    catName, tokenn
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                alert("Category Deleted")
                gettingDataHome();
            } else {
                alert("Could'nt delete")
            }

        } catch (error) {
            console.log(error)
        }

    }


    useEffect(() => {
        gettingDataHome();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
        <div className="dashMain">

            {/* slide */}
            <div className="dashMainSlide">

                <div className="dashMainSlide">

                    {/* img */}
                    <NavLink to="/dashboard/home" style={{ color: "white", textDecoration: "none" }} className="mainImg">
                        <img src={Logo} alt="logo" />
                    </NavLink>

                    <div className="mainServices">
                        <NavLink className="mainServicesspan" to="/dashboard/profile" ><i className="fa-solid fa-user"></i><span>Profile</span></NavLink>
                        <div style={{ display: "flex", flexDirection: "column" }} onMouseEnter={() => setShowProfiles(true)} onMouseLeave={() => setShowProfiles(false)}>
                            <span className="mainServicesspan selectedService"  ><i className="fa-solid fa-box-archive"></i><span>Products</span></span>
                            {
                                showProfiles ? <div style={{ display: "flex", flexDirection: "column" }}  >
                                    <NavLink className="mainServicesspan" to="/dashboard/allproducts">All Products</NavLink>
                                    <NavLink className="mainServicesspan" to="/dashboard/newproducts">New Products</NavLink>
                                    <NavLink className="mainServicesspan" to="/dashboard/productscat">Categories</NavLink>
                                </div> : ""
                            }
                        </div>
                        <NavLink className="mainServicesspan" to="/dashboard/orders"><i className="fa-solid fa-cart-shopping"></i><span>Orders</span></NavLink>
                        <NavLink className="mainServicesspan" to="/dashboard/customers"><i className="fa-solid fa-people-group"></i><span>Customers</span></NavLink>
                        <NavLink style={{ marginLeft: "3px" }} className="mainServicesspan" to="/dashboard/forms"><i className="fa-solid fa-book"></i><span style={{ marginLeft: "3px" }}  >Forms</span></NavLink>
                    </div>

                </div>

            </div>

            {/* Part next to start */}

            <div className="dashMainCntxt" style={{ background: "#ececec" }}>

                <div className="dashCatMain" >

                    <div colassName="dashCatSub" >
                        <h2 style={{ borderLeft: "7px solid rgb(243, 163, 50)", paddingLeft: "20px", position: "relative", left: "35px" }} >EXISTING CATEGORIES</h2>

                        <div className="catNames" style={{ marginTop: "70px" }}>
                            {catData.map((item) => {
                                return (<>
                                    <div>
                                        <span>{item.catName}</span>
                                        <i onClick={(e) => deleteCategory(e, item.catName)} className="fa fa-trash catTrash"></i>
                                    </div>
                                </>)
                            })}
                        </div>

                    </div>

                </div>

                {/* <button className="addNewCat" style={{}}>Add New</button> */}

                <div className="addNewCatDiv">
                    <input type="text" placeholder="Category Name" name="catName" value={catName} onChange={(e) => setcatName(e.target.value)} />
                    <p>{error}</p>
                    <button onClick={postCategory} >Add New</button>
                </div>

            </div>

        </div>

    </>)
}

export default ProductsCat;