import "../../style/Login.css";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import Logo from "../images/logo.jpg";
import Bgimg from "../images/loginGraphics.png";

const Login = () => {

    const [loginData, setLoginData] = useState({
        email: "",
        password: ""
    });

    const navigate = useNavigate();
    const [error, setError] = useState("");

    const setLoginVal = (e) => {
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
    }

    const postLoginDetails = async (e) => {

        e.preventDefault();

        const { email, password } = loginData;

        try {
            const res = await fetch("https://hpzserver.vercel.app/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email, password
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                console.log(data.message);
                Cookies.set('HPZ Hardware Worldwide', data.message, {
                    expires: 10000000000
                });

                navigate(`/en`);
            } else {
                console.log("Login Failed");
                if (data.error === "Invalid credentials") {
                    setError("Invalid credentials, please recheck the Email & Password")
                } else if (data.error === "Enter all the details") {
                    setError("Fill all the entries")
                }
            }

        } catch (error) {
            console.log(error)
        }

    }

    return (<>

        <div className="loginMain">

            <div className="purpleMoon"></div>

            <div className="loginDiv">

                <div className="loginSub1">

                    <div className="loginNav">
                        <NavLink style={{ textDecoration: "none" }} to="/"><img src={Logo} alt="logo" className="logoLogin" /> <span>HPZ Hardware Worldwide</span></NavLink>
                        <NavLink to="/"><i className="fa-solid fa-circle-xmark"></i></NavLink>
                    </div>

                    <form className="loginInputs">
                        <input required autoComplete="off" type="text" name="email" placeholder="Email or Phone" value={loginData.email} onChange={setLoginVal} />
                        <input required autoComplete="off" type="password" name="password" placeholder="Password" value={loginData.password} onChange={setLoginVal} />
                        <p style={{ color: "red" }} >{error}</p>
                        <button className="loginBtn" type="submit" onClick={postLoginDetails}>Login</button>
                        <span>Don't have an account? <NavLink style={{ textDecoration: "none" }} to="/signup">Signup</NavLink></span>
                    </form>

                </div>

                {/* <div className="loginSub2">
                    <div className="loginNav2">
                        <NavLink to="/"><i className="fa fa-house"></i></NavLink>
                    </div>
                    <img src={Bgimg} alt="" className="loginGraphics" />
                </div> */}

            </div>

        </div>

    </>)

}

export default Login;