import "../../style/Products.css";
import Logo from "../images/logo.jpg"
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";

// slider range
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

import Footer from "./Footer";
import Cart from "./Cart";
import featuredProducts from "../products/featuredProducts";
import SearchBox from "./Searchbox";
import NavBar from "./Navbar";

const Product = () => {

    const [minMaxPrice, setMinMaxPrice] = useState([0, 149000]);

    const RecentlyViewed = [
        {
            imageUrl: "https://i.ibb.co/Ksqczs4/Gas-pump.png",
            productName: "Gas Pump",
            companyName: "HPZ",
            actualPrice: 250,
            offerPrice: 230,
            discountPer: 35
        },
        {
            imageUrl: "https://i.ibb.co/yQBwHVr/trouser-rack.png",
            productName: "Trouser Rack",
            companyName: "HPZ",
            actualPrice: 10000,
            offerPrice: 8000,
            discountPer: 35
        },
        {
            imageUrl: "https://i.ibb.co/QFxsqcN/drawer-lock.png",
            productName: "Drawer Lock",
            companyName: "HPZ",
            actualPrice: 100,
            offerPrice: 80,
            discountPer: 35
        },
    ];

    // Scroll to the top of this page when mounted
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<>

        <div className="productsMainDiv" id="main">

            <div className="productsNav">

                {/* Navbar */}
                <NavBar />

            </div>

            <div className="productsDivv">

                <div className="productsFilter">

                    <form>
                        <div className="filterHeading">
                            <h2>Quick Product Search</h2>
                        </div>
                        <div className="productCat">

                            <h6>PRODUCT CATEGORIES</h6>

                            <select id="categories" name="categories">
                                <option value="All Categories" defaultValue>All Categories</option>
                                <option value="Kitchen-Fittings">Kitchen Fittings</option>
                                <option value="Wardrobe-Fittings">Wardrobe Fittings</option>
                                <option value="Glass-Fittings">Glass Fittings</option>
                                <option value="Aluminium-Profile">Aluminium Profile</option>
                            </select>

                        </div>

                        <div className="productBrand">
                            <h6>BRAND</h6>


                            <select name="brand" id="brand">
                                <option value="all" selected>All Categories</option>
                                <option value="HPZ">HPZ</option>
                            </select>

                        </div>

                        <div className="rangeSliderDiv">
                            <h6>Filter By Price</h6>
                            <RangeSlider id="range-slider-yellow" value={minMaxPrice} onInput={setMinMaxPrice} min={0} max={149000} />
                            <div className="rangeVal">
                                <span><i className="fa fa-indian-rupee-sign"></i> {minMaxPrice[0]}</span>
                                <span><i className="fa fa-indian-rupee-sign"></i> {minMaxPrice[1]}</span>
                            </div>
                        </div>

                        <button className="applyFilter">Apply</button>

                    </form>

                    <form style={{ marginTop: "100px" }}>

                        <div className="filterHeading">
                            <h2>Recently Viewed Products</h2>
                        </div>
                        <div className="recentlyViewedDiv">
                            {RecentlyViewed.map((data) => {
                                return (<>
                                    <NavLink to={`/shop/${(data.productName).replaceAll(" ", "-")}`} style={{ textDecoration: "none", color: "black" }} className="recentlyViewedSub">
                                        <div className="recenltViewImg" style={{ backgroundImage: `url(${data.imageUrl})` }} ></div>
                                        <div className="recentlyViewProd">
                                            <span>{data.productName}</span> <br />
                                            <span style={{ position: "relative", top: "-30px" }}>
                                                <strike style={{ fontSize: "13px", padding: "2px 0px", color: "rgb(43, 43, 43)" }} ><i className="fa-solid fa-indian-rupee-sign"></i> {data.actualPrice}</strike>
                                                <span style={{ color: "rgb(60, 60, 193)", fontWeight: "700", paddingLeft: "5px" }} > <i className="fa-solid fa-indian-rupee-sign"></i> {data.offerPrice}</span>
                                            </span>
                                            <div className="toolsrecentView">
                                                <i className="fa-solid fa-cart-shopping"></i>
                                                {/* <i className="fa-solid fa-magnifying-glass"></i> */}
                                                <i className="fa-regular fa-eye"></i>
                                                <i className="fa-regular fa-heart"></i>
                                            </div>
                                        </div>
                                    </NavLink>
                                </>)
                            })}
                        </div>

                    </form>

                </div>

                <div className="productsDisplayDiv">

                    <div className="productDisplaySub1">
                        <h2>Showing Searched Products for {'"JSvar"'}</h2>

                        <select name="brand" id="brand">
                            <option value="all" selected>Sort by</option>
                            <option value="GC">Low to High</option>
                            <option value="GDC">High to Low</option>
                            {/* <option value="J Morita">Popularity</option> */}
                            <option value="Mani">New to Old</option>
                        </select>

                    </div>

                    <div className="productDisplaySub2">

                        <div className="productsPagediv">

                            <div className="featuredPageProducts">
                                {featuredProducts.map((data) => {
                                    return (

                                        <NavLink to={`/shop/${(data.productName).replaceAll(" ", "-")}`} style={{ textDecoration: "none", color: "black" }} className="productsPageSubDiv">
                                            {/* <div className="bgImgProducts" style={{backgroundImage:`url("https://eshantidental.com/wp-content/uploads/2021/05/Aspirating-Syringe-SYRA1.8ml-1-600x447.jpg")`}}> */}
                                            <div className="bgImgProducts" style={{ backgroundImage: `url(${data.imageUrl})` }}>
                                                <button className="discountProducts">
                                                    -{data.discountPer}%
                                                </button>
                                                <div className="toolsProductsPage">
                                                    <i className="fa-solid fa-cart-shopping"></i>
                                                    {/* <i className="fa-solid fa-magnifying-glass"></i> */}
                                                    <i className="fa-regular fa-eye"></i>
                                                    <i className="fa-regular fa-heart"></i>
                                                </div>
                                            </div>
                                            <div className="detailsProducts">
                                                <span style={{ fontWeight: "600", padding: "5px 0px" }}>{data.productName}</span>
                                                <span>{data.companyName}</span>
                                                <span className="pricingProducts">
                                                    <strike style={{ fontSize: "13px", padding: "2px 0px", color: "rgb(43, 43, 43)" }} ><i className="fa-solid fa-indian-rupee-sign"></i> {data.actualPrice}</strike>
                                                    <span style={{ color: "rgb(60, 60, 193)", fontWeight: "700", paddingLeft: "5px" }} > <i className="fa-solid fa-indian-rupee-sign"></i> {data.offerPrice}</span>
                                                </span>
                                            </div>

                                        </NavLink>

                                    )
                                })}

                            </div>
                        </div>
                    </div>



                </div>

            </div>

        </div>
        <Footer />

    </>)

}

export default Product;