import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import "../../style/Searchbar.css";

const SearchBox = () => {

    const [query, setQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const history = useNavigate();

    const [productData, setProductData] = useState([]);

    const gettingData = async (e) => {

        try {
            const res = await fetch(`https://hpzserver.vercel.app/usersasqwzxerdfcv/products`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });

            const data = await res.json();

            if (res.status === 200) {
                setProductData(data.products)
            } else {
                // navigate("/usernotfound");
            }

        } catch (error) {
            console.log(error);
        }

    }

    const handleSearch = (event) => {
        const value = event.target.value;
        setQuery(value);

        if (value.length > 0) {
            const results = productData.filter(item => item.productName.toLowerCase().includes(value.toLowerCase()));
            setFilteredData(results);
            setFilteredData(results.slice(0, 6)); // Limit results to 6
        } else {
            setFilteredData([]);
        }
    };

    // const handleRedirect = (path) => {
    //     history(path);
    // };

    // showing hiding menu
    const [resultsshow, setresultsshow] = useState(false);

    const showMenu = () => {
        if (resultsshow === false) {
            setresultsshow(true)
        } else if (resultsshow === true) {
            setresultsshow(false)
        }
    }

    useEffect(() => {
        gettingData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<>
        <div className="searchBoxMain">

            <div className="searchBox">
                <input type="text"
                    value={query}
                    onChange={handleSearch}
                    placeHolder="Search For Products"
                    onClick={showMenu}
                />
                <div className="searchiconBox">
                    <i className="fa fa-search searchIcon"></i>
                </div>
            </div>

            {
                resultsshow ? <div className="searchbarResults">
                    <div className="searchBarSub">

                        {filteredData.map(item => (
                            <a href={`/shop/${(item.productName).replaceAll(" ", "-")}`} style={{ textDecoration: "none" }} className="searchResultDiv" key={item._id} >
                                <div className="searchResultImgDiv">
                                    <div className="searchResultDivImg" style={{ backgroundImage: `url(${item.image})` }}></div>
                                </div>
                                <div className="searchResultDet">
                                    <span style={{ fontSize: "18px", marginTop: "5px", color: "rgb(43, 43, 43)" }} >{item.productName}</span>
                                    <span className='searchResultDetSpan'>
                                        <strike style={{ fontSize: "13px", padding: "2px 0px", color: "rgb(43, 43, 43)" }} ><i className="fa-solid fa-indian-rupee-sign"></i> {item.actualPrice}</strike>
                                        <span style={{ color: "rgb(60, 60, 193)", fontWeight: "700", paddingLeft: "5px" }} > <i className="fa-solid fa-indian-rupee-sign"></i> {item.discountPrice}</span>
                                    </span>
                                </div>
                            </a>
                        ))}

                    </div>

                    <a href={`/product/${query}`} className="viewAllDiv">
                        VIEW ALL RESULTS
                    </a>

                </div> : ""
            }

        </div>

    </>)
}

export default SearchBox;