import './App.css';
import { Routes, Route } from 'react-router-dom';

//Importing Components
import Home from './components/Home';
import Login from './components/other components/Login';
import SignUp from './components/other components/Signup';
import Product from './components/other components/Products';
import ProductAll from './components/other components/ProductsAll';
import Shop from './components/other components/Shop';
import Logout from './components/other components/Logout';

import PaginationAll from './components/other components/PaginationAll';
import ProductsBySearch from './components/other components/ProductsBySearch';
import CheckOut from './components/other components/Checkout';
import About from './components/other components/About';
import PrivacyPolicy from './components/other components/PrivacyPolicy';
import SRCPolicy from './components/other components/ShippingRefundCancel';
import TermsnConditions from './components/other components/TermsnConditions';
import Contact from './components/other components/Contact';
import TrackOrder from "./components/other components/TrackOrder";
import OrderPlaced from "./components/other components/OrderPlaced";
import WishList from './components/other components/Wishlist';
import CartView from "./components/other components/CartView"
import CategoryShop from './components/other components/CategoryShop';

// Importing Dashboard
import Main from "./dashboard/Main";
import LoginDash from "./dashboard/Login";
import Profile from './dashboard/Profile';
import Forms from './dashboard/Forms';
import Customers from './dashboard/Customers';
import AllProducts from './dashboard/AllProducts';
import AllProDisplay from './dashboard/AllProDisplay';
import NewProduct from './dashboard/NewProduct';
import ProductsCat from './dashboard/ProductsCat';
import Orders from './dashboard/Orders';
import OrdersSearch from './dashboard/OrdersSearch';
import EditProduct from './dashboard/EditProduct';
import Menu2 from "./components/other components/Menu2";

function App() {
  return (
    < >

      <Routes>

        <Route exact path="/" element={<Home />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/allProducts" element={<ProductAll />} />
        <Route exact path="/product/:productname" element={<ProductsBySearch />} />
        <Route exact path="/shop/" element={<Shop />} />
        <Route exact path="/shop/:productname" element={<Shop />} />
        <Route exact path="/category/:productname" element={<CategoryShop />} />
        <Route exact path="/checkout" element={<CheckOut />} />
        <Route exact path="/wishlist" element={<WishList />} />
        <Route exact path="/cart" element={<CartView />} />

        <Route exact path="/about" element={<About />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/refundpolicy" element={<SRCPolicy />} />
        <Route exact path="/termsnconditions" element={<TermsnConditions />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/trackOrder" element={<TrackOrder />} />
        <Route exact path="/orderSuccess" element={<OrderPlaced />} />

        {/* Dashboard */}
        <Route exact path="/dashboard/home" element={<Main />} />
        <Route exact path="/dashboard/login" element={<LoginDash />} />
        <Route exact path="/dashboard/profile" element={<Profile />} />
        <Route exact path="/dashboard/forms" element={<Forms />} />
        <Route exact path="/dashboard/customers" element={<Customers />} />

        <Route exact path="/dashboard/allproducts" element={<AllProDisplay />} />
        <Route exact path="/dashboard/searchproduct" element={<AllProducts />} />
        <Route exact path="/dashboard/newproducts" element={<NewProduct />} />
        <Route exact path="/dashboard/productscat" element={<ProductsCat />} />
        <Route exact path="/dashboard/orders" element={<Orders />} />
        <Route exact path="/dashboard/ordersSearch" element={<OrdersSearch />} />
        <Route exact path="/dashboard/edit/:proName" element={<EditProduct />} />


        {/* Default Page */}
        <Route path="*" element={<Home />} />

      </Routes>

    </>
  )
}

export default App;