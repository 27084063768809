const featuredProducts = [
    {
        _id: "665435626172594239a6f698",
        productName: "Trouser Rack",
        productCat: "Wardrobe Fittings",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 10000,
        discountPrice: 8000,
        image: "https://i.ibb.co/yQBwHVr/trouser-rack.png",
        // brandName: "GDC"
    },
    {
        _id: "665435b76172594239a6f69e",
        productName: "Single Cloth Holder",
        productCat: "Wardrobe Fittings",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 8000,
        discountPrice: 6000,
        image: "https://i.ibb.co/LgQ0SJH/Single-cloth-holder.png",
        // brandName: "GDC"
    },
    {
        _id: "665124c109c864e8a41bfa06",
        productName: "Drawer Lock",
        productCat: "Wardrobe Fittings",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 100,
        discountPrice: 80,
        image: "https://i.ibb.co/QFxsqcN/drawer-lock.png",
        // brandName: "GDC"
    },
    {
        _id: "6650fc3b95bc3138c048fc07",
        productName: "Gas Pump 100 to 150 kgs",
        productCat: "Wardrobe Fittings",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 2000,
        discountPrice: 19000,
        discountPercentage: 20,
        image: "https://i.ibb.co/hf35NTs/gas-pump.png",
        // brandName: "Prevest DenPro"
    },
    {

        _id: "6650fd1b6f483e3b73d726c4",
        productName: "Ratten Basket",
        productCat: "Wardrobe Fittings",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 1350,
        discountPrice: 1080,
        image: "https://i.ibb.co/25gDLS0/ratten-basket.png",
        // brandName: "Prevest DenPro"

    },
    {

        _id: "6650fd796f483e3b73d726ca",
        productName: "Rotating Clothe Basket",
        productCat: "Wardrobe Fittings",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 16000,
        discountPrice: 14000,
        image: "https://i.ibb.co/3dsJY4t/Rotating-cloth-basket.png",
        // brandName: "Prevest DenPro"

    },
    {

        _id: "6650fd4e6f483e3b73d726c7",
        productName: "Sky Track WHeel",
        productCat: "Wardrobe Fittings",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 1000,
        discountPrice: 800,
        image: "https://i.ibb.co/9bWzdWj/sky-track.png",
        // brandName: "Prevest DenPro"

    },
    {

        _id: "6650fd4e6f483e3b73d726c7",
        productName: "Door Stopper",
        productCat: "Wardrobe Fittings",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 300,
        discountPrice: 250,
        image: "https://i.ibb.co/BtTtT2p/door-stopper.png",
        // brandName: "Prevest DenPro"

    },
];


export default featuredProducts;