import "../../style/Login.css";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import Logo from "../images/logo.jpg";
import Bgimg from "../images/loginGraphics.png";

const SignUp = () => {

    const [loginData, setLoginData] = useState({
        name: "",
        email: "",
        phone: "",
        password: "",
        cpassword: "",
        drReg: ""
    });

    const navigate = useNavigate();
    const [error, setError] = useState("");

    const setLoginVal = (e) => {
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
    }

    const postLoginDetails = async (e) => {

        e.preventDefault();

        const { name, email, phone, password, cpassword, drReg } = loginData;

        try {
            const res = await fetch("https://hpzserver.vercel.app/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name, email, phone, password, cpassword, drReg
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                console.log("signin Success");
                alert("Signin Success!")
                navigate(`/login`);
            } else {
                console.log("Registration Failed");
                if (data.error === "Passwords doesnt match") {
                    setError("Passwords doesn't match")
                } else if (data.error === "email already registered") {
                    setError("Email already registered... login : )")
                } else if (data.error === "Enter all the details") {
                    setError("Please fill all the details!")
                }
            }

        } catch (error) {
            console.log(error)
        }

    }

    return (<>

        <div className="loginMain">

            <div className="purpleMoon"></div>

            <div className="loginDiv">

                <div className="loginSub1">

                    <div className="loginNav">
                        <NavLink style={{ textDecoration: "none" }} to="/"><img src={Logo} alt="logo" className="logoLogin" /> <span>HPZ Hardware Worldwide</span></NavLink>
                        <NavLink to="/"><i className="fa-solid fa-circle-xmark"></i></NavLink>
                    </div>

                    <form className="loginInputs">
                        <input style={{ margin: "5px auto" }} required autoComplete="off" type="text" name="name" placeholder="Full Name" value={loginData.name} onChange={setLoginVal} />
                        <input style={{ margin: "5px auto" }} required autoComplete="off" type="email" name="email" placeholder="Email" value={loginData.email} onChange={setLoginVal} />
                        <input style={{ margin: "5px auto" }} required autoComplete="off" type="number" name="phone" placeholder="Phone Number" value={loginData.phone} onChange={setLoginVal} />
                        <input style={{ margin: "5px auto" }} required autoComplete="off" type="password" name="password" placeholder="Password" value={loginData.password} onChange={setLoginVal} />
                        <input style={{ margin: "5px auto" }} required autoComplete="off" type="password" name="cpassword" placeholder="Confirm your Password" value={loginData.cpassword} onChange={setLoginVal} />
                        <input style={{ margin: "5px auto" }} autoComplete="off" type="text" name="drReg" placeholder="Dr. Registration Number (optional)" value={loginData.drReg} onChange={setLoginVal} />
                        <p style={{ color: "red" }} >{error}</p>
                        <button className="loginBtn" type="submit" onClick={postLoginDetails}>Sign Up</button>
                        <span>Already have an account? <NavLink style={{ textDecoration: "none" }} to="/login">Login</NavLink></span>
                    </form>

                </div>

                {/* <div className="loginSub2">
                    <div className="loginNav2">
                        <NavLink to="/"><i className="fa fa-house"></i></NavLink>
                    </div>
                    <img src={Bgimg} alt="" className="loginGraphics" />
                </div> */}

            </div>

        </div>

    </>)

}

export default SignUp;