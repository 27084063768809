import Footer from "./Footer";
import { NavLink } from "react-router-dom";
import Logo from "../images/logo.jpg";
import SearchBox from "./Searchbox";
import Cart from "./Cart";
import NavBar from "./Navbar";

const TrackOrder = () => {
    {/* Track your order */ }
    return (<>

        <div className="section1Mainmost" style={{minHeight:"50vh"}}>
            {/* top line */}
            <div className="aboutMain" style={{ minHeight: "180px" }}>

                <div className="topBarMain">

                    <div className="topBar">
                        <div className="topBarOne">
                            <p style={{ fontFamily: "'Jost', sans-serif" }} >FREE SHIPPING FOR ALL ORDERS ABOVE ₹ 1,999</p>
                        </div>
                        <div className="topBarTwo">
                            <i className="fa-brands fa-facebook-f"></i>
                            <a style={{ textDecoration: "none", color: "black" }} href="/">
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                            <a style={{ textDecoration: "none", color: "black" }} href="https://www.google.com/maps/place/HPZ+WORLDWIDE+PRIVATE+LIMITED/@17.3845779,78.453285,17z/data=!3m1!4b1!4m6!3m5!1s0x3bcb9972611be379:0x58f98e4ab2925934!8m2!3d17.3845779!4d78.4558599!16s%2Fg%2F11s8y3t5qf?entry=tts&g_ep=EgoyMDI0MTAyMy4wIPu8ASoASAFQAw%3D%3D">
                                <i className="fa-brands fa-google"></i>
                            </a>
                            <a style={{ textDecoration: "none", color: "black" }} href="https://wa.me/919440295353">
                                <i className="fa-brands fa-whatsapp"></i>
                            </a>
                            {/* <i className="fa-brands fa-telegram"></i> */}
                        </div>
                    </div>

                </div>

                {/* Navbar */}
                <NavBar />

                <div className="orderTrackMain" id="trackOrder" style={{ marginTop: "30px" }}>
                    <div className="orderTrackDiv">
                        <h2 className="reviewHeading">Track your Order</h2>
                        <span className="tracktxt">To track your order please enter your Order ID in the box below and press the "Track" button.
                            <br />This was given to you on your receipt and in the confirmation email you should have received.</span>
                        <div className="orderInputs">
                            <input type="text" placeholder="Enter Billing Email" />
                            <input type="text" placeholder="Enter Order ID" />
                        </div>
                        <button className="trackBtn" style={{ background: "black" }}>TRACK</button>
                    </div>
                </div>

            </div>
        </div>



        <Footer />
    </>)
}

export default TrackOrder;