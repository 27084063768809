import { slide as Menu } from 'react-burger-menu';
import { useNavigate, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import "../../style/Cart.css";
import Cookies from "js-cookie";

const Test2 = () => {

    const navigate = useNavigate();

    const [cartItems, setCartItems] = useState([]);
    const [total, setTotal] = useState()
    const token = Cookies.get("HPZ Hardware Worldwide");

    const gettingData = async (e) => {
        // console.log(token)
        try {
            const res = await fetch(`https://hpzserver.vercel.app/usersasqwzxerdfcv/cartData`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                setCartItems(data)

                let totalPrice = 0
                data.forEach(item => {
                    setTotal(totalPrice += item.discountPrice * item.quantity)
                });

                // console.log(total)
            } else {
                // navigate("/usernotfound");
            }

        } catch (error) {
            console.log(error);
        }

    }

    const gettingCartVal = () => {
        let totalPrice = 0
        cartItems.forEach(item => {
            setTotal(totalPrice += item.discountPrice * item.quantity)
        });

        // console.log(total)
    }

    const deleteCartItem = async (e, id, porl, result) => {
        e.preventDefault();

        try {
            const res = await fetch("https://hpzserver.vercel.app/usersasqwzxerdfcv/deleteCartItem", {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    id, token
                })
            });


            if (res.status === 200) {
                try {
                    const res = await fetch(`https://hpzserver.vercel.app/usersasqwzxerdfcv/cartData`, {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            token
                        })
                    });

                    const data = await res.json();

                    if (res.status === 200) {
                        setCartItems(data)

                        let totalPrice = 0
                        data.forEach(item => {
                            setTotal(totalPrice += item.discountPrice * item.quantity)
                        });

                        // console.log(total)
                    } else {
                        // navigate("/usernotfound");
                    }

                } catch (error) {
                    console.log(error);
                }
            } else {
                console.log("Couldn't delete")
            }
        } catch (error) {
            console.log(error)
        }

    }

    const editQty = async (e, productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity, color, size) => {
        e.preventDefault();
        // console.log(token, e, productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity)
        try {
            const res = await fetch("https://hpzserver.vercel.app/usersasqwzxerdfcv/post/cartproduct/edit", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token, productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity, color, size
                })
            });


            if (res.status === 200) {
                try {
                    const res = await fetch(`https://hpzserver.vercel.app/usersasqwzxerdfcv/cartData`, {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            token
                        })
                    });

                    const data = await res.json();

                    if (res.status === 200) {
                        setCartItems(data)

                        let totalPrice = 0
                        data.forEach(item => {
                            setTotal(totalPrice += item.discountPrice * item.quantity)
                        });

                        // console.log(total)
                    } else {
                        // navigate("/usernotfound");
                    }

                } catch (error) {
                    console.log(error);
                }

            } else {
                console.log("Couldn't delete")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const updatingCheckoutVal = async (e) => {

        e.preventDefault();

        // console.log(token, e, productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity)
        try {
            const res = await fetch("https://hpzserver.vercel.app/usersasqwzxerdfcv/set/cartVal", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token, total
                })
            });


            if (res.status === 200) {
                console.log("checked out!")
                navigate("/checkout")
            } else {
                console.log("Couldn't update")
            }
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        gettingData();
        gettingCartVal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Menu burgerButtonClassName={"cartBtnMod"} width={350} right customBurgerIcon={<i className='fa fa-shopping-cart'></i>} >
            <h2 className='cartH2'>SHOPPING CART</h2>
            {cartItems.length ? <>
                {/* cart filled */}
                <div className="filledCart">
                    <div className="filledCartSub">
                        {cartItems.map((data) => {
                            return (<>
                                <NavLink style={{ textDecoration: "none", color: "black" }} className="recentlyViewedSubCart">
                                    <NavLink onClick={() => window.location.href = `/shop/${(data.productName).replaceAll(" ", "-")}`} to={`/shop/${(data.productName).replaceAll(" ", "-")}`} style={{ textDecoration: "none", color: "black", backgroundImage: `url(${data.image})` }} className="recenltViewImgCart"></NavLink>
                                    <div className="recentlyViewProdCart">
                                        <span style={{ color: "#000" }}>{data.productName}</span> <br />
                                        <span style={{ position: "relative", top: "-22px" }}>
                                            <strike style={{ fontSize: "13px", padding: "2px 0px", color: "rgb(43, 43, 43)" }} ><i className="fa-solid fa-indian-rupee-sign"></i> {data.actualPrice}</strike>
                                            <span style={{ color: "rgb(60, 60, 193)", fontWeight: "700", paddingLeft: "5px" }} > <i className="fa-solid fa-indian-rupee-sign"></i> {data.discountPrice}</span>
                                        </span>
                                        {
                                            data.color? <span style={{ position: "relative", top: "-15px" , left:"5px", fontSize:"13px" }}>{data.color}</span> : ""
                                        }
                                        {
                                            data.size? <span style={{ position: "relative", top: "-15px" , left:"5px", fontSize:"13px" }}>{data.size}</span> : ""
                                        }
                                        <div className="quantityShopCart" style={{ position: "relative", top: "-15px" }}>
                                            <span>
                                                <i className="fa fa-minus" onClick={(e) => { data.quantity = data.quantity - 1; editQty(e, data.productName, data.productCat, data.inStock, data.actualPrice, data.discountPrice, data.image, data.brandName, data.quantity, data.color, data.size); }} ></i>
                                                <span>{data.quantity}</span>
                                                <i className="fa fa-plus" onClick={(e) => { data.quantity = data.quantity + 1; editQty(e, data.productName, data.productCat, data.inStock, data.actualPrice, data.discountPrice, data.image, data.brandName, data.quantity, data.color, data.size); }}></i>
                                                <i onClick={(e) => deleteCartItem(e, data._id)} className="fa fa-trash trashBtn" ></i>
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>
                            </>)
                        })}
                    </div>

                    <div className="checkoutCartDiv">
                        <div className="subTotalDiv">
                            <p>SUBTOTAL:</p>
                            <span style={{ color: "rgb(60, 60, 193)", fontWeight: "700", paddingLeft: "5px" }} > <i className="fa-solid fa-indian-rupee-sign"></i> {total}</span>
                        </div>
                        <NavLink onClick={updatingCheckoutVal} className="checkoutBtn">CHECKOUT</NavLink>
                    </div>

                </div>
            </> : <>
                {/* empty cart */}
                <div className="emptyCart">
                    <div>
                        <i className="fa-brands fa-opencart"></i>
                        <p>Your cart is empty</p>
                    </div>
                    <NavLink style={{ textDecoration: "none" }} className="menu-itemCart" to="/">RETURN TO SHOP</NavLink>
                </div>
            </>}


        </Menu>
    );
}

export default Test2;