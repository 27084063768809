import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Banner1 from "../images/HomeBanner1.png";
import Banner2 from "../images/HomeBanner2.png";
import Banner3 from "../images/HomeBanner3.png";

const HomeBanner = () => {
    return (<>
        <Carousel showThumbs={false} width={"100%"} infiniteLoop={true} autoPlay={true} interval={3000} showStatus={false}>

            {/* <div className="carouselSide carouselSide1"></div> */}
            <img src={Banner1} className="bannerImg" alt="banner" />

            <img src={Banner2} className="bannerImg" alt="banner" />

            <img src={Banner3} className="bannerImg" alt="banner" />

        </Carousel>
    </>)
}

export default HomeBanner;