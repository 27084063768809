import { NavLink } from "react-router-dom";
import "../../style/Footer.css";
import Logo from "../images/logo.jpg";
// import Logo2 from "../images/logowtagline-removebg-full.png";


const Footer = () => {

    return (
        <>
            <div className="footerMain">

                <div className="footerDiv">

                    {/* section 1 */}

                    <div className="footerSub1">

                        <div className="footerImgntagline">
                            <img className="footerLogo" src={Logo} alt="logo" />
                            <span>
                                Our motto at HPZ Worldwide Pvt. Ltd. is to facilitate a vast range of high quality & reliable hardware products.
                            </span>
                        </div>

                        <div className="footerContact">

                            <span>
                                <i className="fa fa-location-dot"></i> 3-1-1250, 3rd & 4th floor, Seetarambagh, Hyderabad, Telangana 500001.
                            </span>
                            <span>
                                <i className="fa fa-mobile-screen-button"></i> +91 9440295353 , +91 8885030503
                            </span>
                            <span>
                                <i className="fa fa-envelope"></i>sales@hpzhardware.in
                            </span>

                        </div>

                    </div>

                    {/* Section 2 */}

                    <div className="footerSub2">
                        <div className="footerSub2Sub">

                            <div className="footerLinks">
                                <div>
                                    <p>Quick Links</p>
                                    <span><NavLink className="footerLinksNav" to="/" >Home</NavLink></span>
                                    <span><NavLink className="footerLinksNav" to="/allproducts" >Shop</NavLink></span>
                                    <span><NavLink className="footerLinksNav" to="/about" >About Us</NavLink></span>
                                </div>
                            </div>

                            <div className="footerLinks">
                                <div>
                                    <p>POLICY</p>
                                    <span><NavLink className="footerLinksNav" to="/privacy-policy"  >Privacy Policy </NavLink></span>
                                    <span><NavLink className="footerLinksNav" to="/termsnconditions"  >Terms & Conditions </NavLink></span>
                                    <span><NavLink className="footerLinksNav" to="/refundpolicy"  >Shipping, Refund, <br />Cancellation policy </NavLink></span>
                                </div>
                            </div>

                            <div className="footerLinks">
                                <div>
                                    <p>Support</p>
                                    <span><NavLink className="footerLinksNav" to="/contact"  >Contact us</NavLink></span>
                                    <span><a className="footerLinksNav" href="#trackOrder" onClick={()=>  window.location.href = "/trackOrder"} >Track Order</a></span>
                                </div>
                            </div>

                        </div>

                        <div className="footerSub2Sec">
                            <div className="socialLinks">
                                <p>Get in touch</p>
                                <a href="/"><i className="fa-brands fa-facebook-f"></i></a>

                                <a href="/">
                                    <i className="fa-brands fa-instagram"></i>
                                </a>

                                <a  href="https://maps.app.goo.gl/QAwXFMbrysUVfkAy5">
                                    <i className="fa-brands fa-google"></i>
                                </a>

                                <a  href="https://wa.me/919440295353">
                                    <i className="fa-brands fa-whatsapp"></i>
                                </a>

                                {/* <i className="fa-brands fa-telegram"></i> */}

                                {/* <a href="/"><i className="fa-brands fa-telegram"></i></a> */}
                            </div>
                        </div>
                    </div>

                </div>

                <p className="copyRightTxt" ><i className="fa-regular fa-copyright"></i> HPZ Hardware 2024. </p>

            </div>

        </>
    )

}

export default Footer;