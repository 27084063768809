import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const TestimonialsSlider = () => {
    return (<>
        <Carousel showIndicators={false} width={"90%"} infiniteLoop={true} autoPlay={true} interval={5000} showStatus={false}>

            <div className="testiSub">
                <i class="fa-solid fa-quote-left quoteStart"></i>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur cumque cum quia voluptas alias id totam quo tenetur molestiae ipsum. Ratione totam aperiam consectetur ipsa, qui voluptas. Molestias, quaerat minima?</p>
                <i class="fa-solid fa-quote-right quoteEnd"></i>
                <div className="testName">
                    <span>XXX</span>
                    <span>CEO, xxx</span>
                </div>
            </div>

            <div className="testiSub">
                <i class="fa-solid fa-quote-left quoteStart"></i>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur cumque cum quia voluptas alias id totam quo tenetur molestiae ipsum. Ratione totam aperiam consectetur ipsa, qui voluptas. Molestias, quaerat minima?</p>
                <i class="fa-solid fa-quote-right quoteEnd"></i>
                <div className="testName">
                    <span>XXX</span>
                    <span>CEO, xxx</span>
                </div>
            </div>

            <div className="testiSub">
                <i class="fa-solid fa-quote-left quoteStart"></i>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur cumque cum quia voluptas alias id totam quo tenetur molestiae ipsum. Ratione totam aperiam consectetur ipsa, qui voluptas. Molestias, quaerat minima?</p>
                <i class="fa-solid fa-quote-right quoteEnd"></i>
                <div className="testName">
                    <span>XXX</span>
                    <span>CEO, xxx</span>
                </div>
            </div>

        </Carousel>
    </>)
}

export default TestimonialsSlider;