const featuredProducts3 = [
    {
        _id: "6651a17ec11b7b3f8b5d0756",
        productName: "C GOLA PROFILE",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 2500,
        discountPrice: 2000,
        image: "https://i.ibb.co/FbG0WNF/c-gola-profile.png",
        // brandName: "GDC",
    },
    {
        _id: "6651a219c11b7b3f8b5d0767",
        productName: "END CAP G PROFILE HANDLE CAP",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 120,
        discountPrice: 100,
        image: "https://i.ibb.co/kDPRxN6/end-cap-g-profile-handle-cap.png",
        // brandName: "GDC"
    },
    {
        _id: "6651a17ec11b7b3f8b5d0756",
        productName: "G PROFILE",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 1700,
        discountPrice: 1500,
        image: "https://i.ibb.co/kDCxTkN/g-profile.png",
        // brandName: "GDC",
    },
    {
        _id: "6651a219c11b7b3f8b5d0767",
        productName: "L GOLA PROFILE",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 2000,
        discountPrice: 1800,
        image: "https://i.ibb.co/x33bQ9z/l-gola-profile.png",
        // brandName: "GDC"
    },
    {
        _id: "6651a219c11b7b3f8b5d0767",
        productName: "SHUTTER PROFILE",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 2500,
        discountPrice: 2100,
        image: "https://i.ibb.co/f49B8NL/shutter-gola-profile.png",
        // brandName: "GDC"
    },
    {
        _id: "6651a219c11b7b3f8b5d0767",
        productName: "Aluminium T Profile",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 350,
        discountPrice: 300,
        image: "https://i.ibb.co/rfz2kPq/Aluminium-T-Profile.png",
        // brandName: "GDC"
    },
    {
        _id: "6651a219c11b7b3f8b5d0767",
        productName: "SS T Profile",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 1000,
        discountPrice: 900,
        image: "https://i.ibb.co/fNqNWMg/SS-T-Profile.png",
        // brandName: "GDC"
    },
    {
        _id: "6651a219c11b7b3f8b5d0767",
        productName: "Profile Connector",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 100,
        discountPrice: 80,
        image: "https://i.ibb.co/fDBVV4w/Profile-T-Connector.png",
        // brandName: "GDC"
    },
]

export default featuredProducts3;