import "../../style/Products.css";
import Logo from "../images/logo.jpg"
import { NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";

// slider range
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

import Footer from "./Footer";
import NavBar from "./Navbar";

const WishList = () => {

    const [minMaxPrice, setMinMaxPrice] = useState([0, 149000]);

    const RecentlyViewed = [
        {
            imageUrl: "https://i.ibb.co/Ksqczs4/Gas-pump.png",
            productName: "Gas Pump",
            companyName: "HPZ",
            actualPrice: 250,
            offerPrice: 230,
            discountPer: 35
        },
        {
            imageUrl: "https://i.ibb.co/yQBwHVr/trouser-rack.png",
            productName: "Trouser Rack",
            companyName: "HPZ",
            actualPrice: 10000,
            offerPrice: 8000,
            discountPer: 35
        },
        {
            imageUrl: "https://i.ibb.co/QFxsqcN/drawer-lock.png",
            productName: "Drawer Lock",
            companyName: "HPZ",
            actualPrice: 100,
            offerPrice: 80,
            discountPer: 35
        },
    ];


    const tokenn = Cookies.get("HPZ Hardware Worldwide");
    const navigate = useNavigate();

    const [productDataAll, setProductDataAll] = useState([]);
    const [productData, setProductData] = useState([]);

    const gettingProductData = async (e) => {

        try {
            const res = await fetch(`https://hpzserver.vercel.app/isuserauthenticateprofile`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    tokenn
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                setProductData(data.rootUser.wishList)
                setProductDataAll(data.rootUser.wishList)
                console.log(data)
            } else {
                // navigate("/usernotfound");
            }

        } catch (error) {
            console.log(error);
        }

    }

    // Pagination

    const [recordsPerPage, setRecordsPerPage] = useState(9);
    const records = productData.slice(0, recordsPerPage);
    const [loadMorePro, setloadMorePro] = useState("Load More")

    const loadMore = () => {
        // console.log(recordsPerPage, productData.length)
        setRecordsPerPage(recordsPerPage + 9)
        if (recordsPerPage <= productData.length) {
            setloadMorePro("Load More")
        } else {
            setloadMorePro("")
        }
    }

    // Cart and wishlist

    // add to cart
    const addToCart = async (e, productName, productCat, inStock, actualPrice, discountPrice, image, brandName) => {

        e.preventDefault();
        console.log(productName, productCat, inStock, actualPrice, discountPrice, image, brandName, tokenn)

        try {
            const res = await fetch("https://hpzserver.vercel.app/usersasqwzxerdfcv/post/cart", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity: 1, tokenn
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                console.log(data.message);
                console.log("added to cart")
                window.location.reload();
            } else {
                console.log("Login Failed");
                if (data.error === "User not found") {
                    console.log(data.error)
                    navigate(`/login`);
                }
            }

        } catch (error) {
            console.log(error)
        }

    }

    // add to wishList
    const addToWishList = async (e, productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity) => {

        e.preventDefault();
        // console.log(productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity, tokenn)

        try {
            const res = await fetch("https://hpzserver.vercel.app/usersasqwzxerdfcv/post/wishlist", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity, tokenn
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                console.log(data.message);
                console.log("added to wishlist")
                window.location.reload();
            } else {
                console.log("error");
                if (data.error === "User not found") {
                    console.log(data.error)
                    navigate(`/login`);
                } else if (data.error === "Already added the to Wishlist !") {
                    alert(data.error)
                }
            }

        } catch (error) {
            console.log(error)
        }

    }

    const [showSortBt, setSortBy] = useState(false)
    const [sortByText, setsortByText] = useState("Sort by")

    const sortLowToHigh = () => {
        const lowToHigh = productData.sort((a, b) => a.discountPrice - b.discountPrice);
        setsortByText("Low to High")
        setProductData(lowToHigh)
        setSortBy(false)
    }

    const sortHighToLow = () => {
        const highToLow = productData.sort((a, b) => b.discountPrice - a.discountPrice);
        setsortByText("High to Low")
        setProductData(highToLow)
        setSortBy(false)
    }

    // quick product search
    const [stateForCategory, setStateForCategory] = useState("All Categories")
    const [stateForBrand, setStateForBrand] = useState("All Brands")

    const filteringSearch = (e) => {

        e.preventDefault();

        if (stateForCategory === "All Categories" && stateForBrand === "All Brands") {

            const filtData = productDataAll.filter((data) => {
                return data.discountPrice >= minMaxPrice[0] && data.discountPrice <= minMaxPrice[1]
            });

            setProductData(filtData);

        } else if (stateForCategory === "All Categories") {

            const filtData = productDataAll.filter((data) => {
                return data.discountPrice >= minMaxPrice[0] && data.discountPrice <= minMaxPrice[1] && data.brandName === stateForBrand
            });

            setProductData(filtData);

        } else if (stateForBrand === "All Brands") {

            const filtData = productDataAll.filter((data) => {
                return data.discountPrice >= minMaxPrice[0] && data.discountPrice <= minMaxPrice[1] && data.productCat === stateForCategory
            });

            setProductData(filtData);

        } else {

            const filtData = productDataAll.filter((data) => {
                return data.discountPrice >= minMaxPrice[0] && data.discountPrice <= minMaxPrice[1] && data.productCat === stateForCategory && data.brandName === stateForBrand
            });

            setProductData(filtData);

        }

    }

    const deleteWishItem = async (e, productName) => {
        e.preventDefault();

        try {
            const res = await fetch("https://hpzserver.vercel.app/usersasqwzxerdfcv/deleteWishlistItem", {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    productName, token: tokenn
                })
            });


            if (res.status === 200) {
                gettingProductData();
            } else {
                console.log("Couldn't delete")
            }
        } catch (error) {
            console.log(error)
        }

    }

    // Scroll to the top of this page when mounted
    useEffect(() => {
        gettingProductData();
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<>

        <div className="productsMainDiv" id="main">

            <div className="productsNav">

                {/* Navbar */}
                <NavBar />

            </div>

            <div className="productsDivv">

                <div className="productsFilter">

                    <form>
                        <div className="filterHeading">
                            <h2>Quick Product Search</h2>
                        </div>
                        <div className="productCat">

                            <h6>PRODUCT CATEGORIES</h6>

                            <select id="categories" name="categories" onChange={(e) => setStateForCategory(e.target.value)}>
                                <option value="All Categories" defaultValue>All Categories</option>
                                <option value="Kitchen-Fittings">Kitchen Fittings</option>
                                <option value="Wardrobe-Fittings">Wardrobe Fittings</option>
                                <option value="Glass-Fittings">Glass Fittings</option>
                                <option value="Aluminium-Profile">Aluminium Profile</option>
                            </select>

                        </div>

                        <div className="productBrand">
                            <h6>BRAND</h6>


                            <select name="brand" id="brand" onChange={(e) => setStateForBrand(e.target.value)}>
                                <option value="All Categories" selected>All Categories</option>
                                <option value="HPZ">HPZ</option>
                            </select>

                        </div>

                        <div className="rangeSliderDiv">
                            <h6>Filter By Price</h6>
                            <RangeSlider id="range-slider-yellow" value={minMaxPrice} onInput={setMinMaxPrice} min={0} max={149000} />
                            <div className="rangeVal">
                                <span><i className="fa fa-indian-rupee-sign"></i> {minMaxPrice[0]}</span>
                                <span><i className="fa fa-indian-rupee-sign"></i> {minMaxPrice[1]}</span>
                            </div>
                        </div>

                        <button className="applyFilter" onClick={(e) => filteringSearch(e)}>Apply</button>

                    </form>

                    <form style={{ marginTop: "50px" }}>

                        <div className="filterHeading">
                            <h2>Recently Viewed Products</h2>
                        </div>
                        <div className="recentlyViewedDiv">
                            {RecentlyViewed.map((data) => {
                                return (<>
                                    <NavLink to={`/shop/${(data.productName).replaceAll(" ", "-")}`} style={{ textDecoration: "none", color: "black" }} className="recentlyViewedSub">
                                        <div className="recenltViewImg" style={{ background: `url("${data.imageUrl}")`, backgroundSize: "100% 100%" }} ></div>
                                        <div className="recentlyViewProd">
                                            <span>{data.productName}</span> <br />
                                            <span style={{ position: "relative", top: "-30px" }}>
                                                <strike style={{ fontSize: "13px", padding: "2px 0px", color: "rgb(43, 43, 43)" }} ><i className="fa-solid fa-indian-rupee-sign"></i> {data.actualPrice}</strike>
                                                <span style={{ color: "rgb(60, 60, 193)", fontWeight: "700", paddingLeft: "5px" }} > <i className="fa-solid fa-indian-rupee-sign"></i> {data.offerPrice}</span>
                                            </span>
                                            <div className="toolsrecentView">
                                                <i className="fa-solid fa-cart-shopping"></i>
                                                {/* <i className="fa-solid fa-magnifying-glass"></i> */}
                                                <i className="fa-regular fa-eye"></i>
                                                <i className="fa-regular fa-heart"></i>
                                            </div>
                                        </div>
                                    </NavLink>
                                </>)
                            })}
                        </div>

                    </form>

                </div>

                <div className="productsDisplayDiv">


                    {/* All Products */}

                    <center>
                        <h2 style={{ fontFamily: "Montserrat, sans-serif", marginBottom: "30px" }}>Wishlist <i style={{ color: "red", marginLeft: "10px" }} className="fa fa-heart"></i></h2>
                    </center>

                    <div className="productDisplaySub1" style={{ flexDirection: "row-reverse" }}>

                        <div name="brand" id="brand" className="sortByDiv" style={{ float: "right" }}>
                            <p value="all" onClick={() => showSortBt ? setSortBy(false) : setSortBy(true)} > {sortByText} <i className="fa-solid fa-chevron-down"></i></p>

                            {
                                showSortBt ? <div className="sortOptions">
                                    <span value="GC" onClick={sortLowToHigh}>Low to High</span>
                                    <span value="GDC" onClick={sortHighToLow}>High to Low</span>
                                </div> : ""
                            }
                        </div>

                    </div>

                    <div className="productDisplaySub2" >

                        <div className="productsPagediv">

                            <div className="featuredPageProducts">
                                {records.map((data) => {
                                    return (

                                        <NavLink to={`/shop/${(data.productName).replaceAll(" ", "-")}`} style={{ textDecoration: "none", color: "black" }} className="productsPageSubDiv">
                                            {/* <div className="bgImgProducts" style={{backgroundImage:`url("https://eshantidental.com/wp-content/uploads/2021/05/Aspirating-Syringe-SYRA1.8ml-1-600x447.jpg")`}}> */}
                                            <div className="bgImgProducts" style={{ backgroundImage: `url(${data.image})` }}>
                                                <button className="discountProducts">
                                                    -{Math.round(((data.actualPrice - data.discountPrice) * 100) / data.actualPrice)}%
                                                </button>
                                                <div className="toolsProductsPage">
                                                    <i className="fa-solid fa-cart-shopping" onClick={(e) => addToCart(e, data.productName, data.productCat, data.inStock, data.actualPrice, data.discountPrice, data.image, data.brandName)}></i>
                                                    {/* <i className="fa-solid fa-magnifying-glass"></i> */}
                                                    <i className="fa-regular fa-eye"></i>
                                                    <i className="fa-solid fa-trash" onClick={(e) => deleteWishItem(e, data.productName)} ></i>
                                                </div>
                                            </div>
                                            <div className="detailsProducts">
                                                <span style={{ fontWeight: "600", padding: "5px 0px" }}>{data.productName}</span>
                                                <span>{data.brandName}</span>
                                                <span className="pricingProducts">
                                                    <strike style={{ fontSize: "13px", padding: "2px 0px", color: "rgb(43, 43, 43)" }} ><i className="fa-solid fa-indian-rupee-sign"></i> {data.actualPrice}</strike>
                                                    <span style={{ color: "rgb(60, 60, 193)", fontWeight: "700", paddingLeft: "5px" }} > <i className="fa-solid fa-indian-rupee-sign"></i> {data.discountPrice}</span>
                                                </span>
                                            </div>

                                        </NavLink>

                                    )
                                })}

                            </div>

                            <div className="loadMoreDiv">
                                {
                                    loadMorePro ? <button onClick={loadMore} >Load More</button> : ""
                                }
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
        <Footer />

    </>)

}

export default WishList;